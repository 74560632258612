<template>
	<v-form ref="form" lazy-validation>
		<div v-for="(select, index) in selects" :key="select.id" :to="select.id">
			<v-select
				v-model="form[index]"
				:items="select.items"
				:label="select.label || 'Select a category'"
				item-text="text"
				return-object
				outlined
				required
				@change="selectionChanged(index)"
			></v-select>
		</div>
		<div class="pt-5"></div>
		<div v-if="formFetching">
			<v-progress-circular
				class="mb-10 mt-3"
				:size="70"
				color="primary"
				indeterminate
			></v-progress-circular>
		</div>
		<v-divider v-if="showButtons"></v-divider>
		<div v-if="showUserField" class="transition">
			<div class="guild-user-text transition">
				<v-avatar size="45" class="avatar-icon" v-if="userAvatarUrl">
					<v-img :src="userAvatarUrl" alt="guild" />
				</v-avatar>
				<div class="transition">
					<v-card-title class="pl-0 pt-8">{{ userIdTitle }}</v-card-title>
					<v-card-subtitle class="pl-0">
						<a
							href="https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-"
							target="_blank"
							class="how-text"
							>How do I get a user ID?</a
						>
					</v-card-subtitle>
				</div>
			</div>
			<v-text-field
				:loading="fetchingUser"
				label="Enter user ID"
				:error-messages="invalidUser"
				:rules="userRules"
				required
				clearable
				outlined
				@input="typingUser"
			></v-text-field>
		</div>
		<div v-if="showGuildField">
			<div class="guild-user-text">
				<v-avatar size="45" class="avatar-icon" v-if="guildAvatarUrl">
					<v-img :src="guildAvatarUrl" alt="guild" />
				</v-avatar>
				<div>
					<v-card-title class="pl-0 pt-8">{{ guildIdTitle }}</v-card-title>
					<v-card-subtitle class="pl-0">
						<a
							href="https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-"
							target="_blank"
							class="how-text"
							>How do I get a guild ID?</a
						>
					</v-card-subtitle>
				</div>
			</div>
			<v-text-field
				:loading="fetchingGuild"
				:error-messages="invalidGuild"
				label="Enter guild ID"
				:rules="guildRules"
				required
				clearable
				outlined
				@input="typingGuild"
			></v-text-field>
		</div>
		<v-text-field
			v-if="showGuildInvite"
			v-model="guildInvite"
			label="Enter guild invite link"
			:rules="inviteRules"
			required
			clearable
			outlined
		></v-text-field>
		<v-textarea
			v-if="showTextField"
			v-model="text"
			label="Provide some evidence"
			:rules="fieldRules"
			counter="725"
			required
			outlined
		></v-textarea>
		<MultiFileUpload
			v-if="showImageField"
			ref="fileUpload"
			@files="files = $event"
		/>
		<div class="file-messages" v-if="showImageField">
			<div class="filesize-message">
				{{ formattedFileSize }}
			</div>
			<div class="error-message" v-if="evidenceError">
				{{ evidenceError }}
			</div>
		</div>
		<v-card-actions v-if="showButtons">
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="submit" :loading="submittingForm">
				Submit
			</v-btn>
		</v-card-actions>
	</v-form>
</template>

<script>
import MultiFileUpload from '@/components/MultiFileUpload';

export default {
	name: 'ReportCardFormInfo',
	components: { MultiFileUpload },
	data: () => ({
		formFetching: true,
		form: [],
		categories: [],
		selects: [],
		submittingForm: false,
		files: [],
		text: null,
		evidenceError: null,
		guildInvite: null,

		invalidUser: [],
		userTimeout: null,
		fetchingUser: false,
		fetchedUser: null,

		invalidGuild: [],
		guildTimeout: null,
		fetchingGuild: null,
		fetchedGuild: null,

		userRules: [(v) => !!v || 'Please include a user id'],
		guildRules: [(v) => !!v || 'Please include a guild id'],
		inviteRules: [
			(v) => !!v || 'Please include a guild invite link',
			(v) =>
				/(https:\/\/)?(www\.)?(((discord(app)?)?\.com\/invite)|((discord(app)?)?\.gg))\/(?<invite>.+)/gi.test(
					v.trim()
				) || 'Invalid invite link',
		],
		fieldRules: [
			(v) => !!v || 'Cannot be blank',
			(v) => (!!v && v.length > 50) || 'Evidence is too short',
		],
	}),
	computed: {
		showTextField() {
			return !!this.form.length && !this.form[this.form.length - 1]?.selections;
		},
		showImageField() {
			return (
				!!this.form.length && this.form[this.form.length - 1]?.requires_image
			);
		},
		showUserField() {
			return (
				!!this.form.length && this.form[this.form.length - 1]?.requires_user
			);
		},
		showGuildField() {
			return (
				!!this.form.length && this.form[this.form.length - 1]?.requires_guild
			);
		},
		showGuildInvite() {
			return (
				!!this.form.length && this.form[this.form.length - 1]?.requires_invite
			);
		},
		showButtons() {
			return !!this.form.length && !this.form[this.form.length - 1]?.selections;
		},
		userIdTitle() {
			if (this.username) {
				return `Creating a report for ${this.username}`;
			} else {
				return `Please enter the user's ID`;
			}
		},
		guildIdTitle() {
			if (this.guildname) {
				return `Creating a report for ${this.guildname}`;
			} else {
				return `Please enter the guild's ID`;
			}
		},
		username() {
			if (this.fetchedUser) {
				return `${this.fetchedUser.username}#${this.fetchedUser.discriminator}`;
			} else {
				return '';
			}
		},
		guildname() {
			if (this.fetchedGuild) {
				return this.fetchedGuild.name;
			} else {
				return '';
			}
		},
		userAvatarUrl() {
			if (this.fetchedUser?.avatar) {
				return `https://cdn.discordapp.com/avatars/${this.fetchedUser.id}/${this.fetchedUser.avatar}.png`;
			}
			return null;
		},
		guildAvatarUrl() {
			if (this.fetchedGuild?.avatar) {
				return `https://cdn.discordapp.com/icons/${this.fetchedGuild.id}/${this.fetchedGuild.avatar}.png`;
			}
			return null;
		},
		formattedFileSize() {
			const size = this.fileSize;
			let sizeString = '0B';
			const mb = 1024 * 1024;
			const kb = 1024;
			if (size > mb) {
				sizeString = Math.round((size / mb) * 10) / 10 + 'MB';
			} else if (size > kb) {
				sizeString = Math.round((size / kb) * 10) / 10 + 'KB';
			} else {
				sizeString = size + 'B';
			}
			return sizeString + ' / 5MB';
		},
		fileSize() {
			return this.files.reduce((prev, curr) => {
				return prev + curr.size;
			}, 0);
		},
	},
	methods: {
		setSelects() {
			const result = [];
			if (this.categories.length) {
				result.push({
					id: result.length,
					items: this.categories,
				});
			}
			this.form.forEach((category) => {
				if (category.selections) {
					result.push({
						id: result.length,
						items: category.selections,
						label: category.label,
					});
				}
			});
			this.selects = result;
		},
		selectionChanged(index) {
			this.form = this.form.splice(0, index + 1);
			this.setSelects();
		},
		validId(id) {
			return /^\d{15,20}$/.test(id);
		},
		resetFetchedUser() {
			this.invalidUser = [];
			this.fetchedUser = null;
			this.fetchingUser = false;
		},
		typingUser(id) {
			this.resetFetchedUser();
			clearTimeout(this.userTimeout);
			if (!this.validId(id)) {
				return;
			}
			this.fetchingUser = true;
			this.userTimeout = setTimeout(() => {
				this.fetchUsername(id);
			}, 2000);
		},
		async fetchUsername(id) {
			this.resetFetchedUser();
			if (!this.validId(id)) {
				return;
			}
			this.fetchingUser = true;
			try {
				const user = await this.$store.dispatch('getUserById', id);
				this.fetchedUser = user;
			} catch (err) {
				this.invalidUser = ['Could not find user with this ID'];
			} finally {
				this.fetchingUser = false;
			}
		},
		resetFetchedGuild() {
			this.invalidGuild = [];
			this.fetchedGuild = null;
			this.fetchingGuild = false;
		},
		typingGuild(id) {
			this.resetFetchedGuild();
			clearTimeout(this.guildTimeout);
			if (!this.validId(id)) {
				return;
			}
			this.fetchingGuild = true;
			this.guildTimeout = setTimeout(() => {
				this.fetchGuildname(id);
			}, 2000);
		},
		async fetchGuildname(id) {
			this.resetFetchedGuild();
			if (!this.validId(id)) {
				return;
			}
			this.fetchingGuild = true;
			try {
				const guild = await this.$store.dispatch('getGuildById', id);
				this.fetchedGuild = guild;
			} catch (err) {
				this.invalidGuild = ['Could not find guild with this ID'];
			} finally {
				this.fetchingGuild = false;
			}
		},
		async submit() {
			this.submittingForm = true;

			let hasError = false;
			if (this.showUserField && !this.fetchedUser) {
				this.invalidUser = ['Could not find user with this ID'];
				hasError = true;
			} else {
				this.invalidUser = [];
			}
			if (this.showGuildField && !this.fetchedGuild) {
				this.invalidGuild = ['Could not find guild with this ID'];
				hasError = true;
			} else {
				this.invalidGuild = [];
			}

			if (this.files.length < 2) {
				this.evidenceError = 'You must include at least two images';
				hasError = true;
			} else if (this.files.length > 7) {
				this.evidenceError = 'You have too many images';
				hasError = true;
			} else if (this.fileSize > 1024 * 1024 * 5) {
				this.evidenceError = 'Total file size cannot exceed 5MB';
				hasError = true;
			} else {
				this.evidenceError = false;
			}

			if (
				!this.$refs.form.validate() ||
				hasError ||
				(await this.checkSelfReport()) ||
				(await this.showWarning())
			) {
				this.submittingForm = false;
				return;
			}

			const form = {
				selections: this.form.map((ele) => ele.id),
				userId: this.showUserField ? this.fetchedUser.id : undefined,
				guildId: this.showGuildField ? this.fetchedGuild.id : undefined,
				guildInvite: this.showGuildInvite ? this.guildInvite : undefined,
				text: this.text,
			};

			try {
				await this.$store.dispatch('submitReport', { files: this.files, form });
				this.resetForm();
				this.$emit('done');
			} catch (err) {
				console.error(err);
				return this.displayFail();
			} finally {
				this.submittingForm = false;
			}
		},
		resetForm() {
			this.form = [];
			this.resetFetchedUser();
			this.resetFetchedGuild();
			this.text = null;
			this.setSelects();
			this.$refs.fileUpload.removeFiles();
		},
		displayFail() {
			const opt = {
				text: 'Failed to send report. Please try again later',
				imgUrl: 'owo-cry.png',
				buttons: [
					{
						text: 'Okay...',
						color: 'primary',
					},
				],
			};
			return this.$modal(opt).showError();
		},
		async checkSelfReport() {
			if (!this.showUserField) return false;
			const userId = this.fetchedUser?.id;
			if (userId == this.$store.getters.user.id) {
				const opt = {
					text: "Silly! You can't report yourself >:C",
					imgUrl: 'owo-cry.png',
					buttons: [
						{
							text: 'Sowwy',
							color: 'primary',
						},
					],
				};
				await this.$modal(opt).showError();
				this.submittingForm = false;
				return true;
			}
		},
		async showWarning() {
			const opt = {
				text: 'Please note that trolling or abusing reports will result in your account being banned!',
				buttons: [
					{
						text: 'Whoops, nvm!',
						color: 'error',
						returns: false,
					},
					{
						text: 'Okay!',
						color: 'primary',
						returns: true,
					},
				],
			};
			return !(await this.$modal(opt).showWarn());
		},
	},
	async mounted() {
		try {
			const categories = await this.$store.dispatch('getReportCategories');
			categories.forEach((category) => {
				if (!category.disabled) {
					this.categories.push(category);
				}
			});
			this.setSelects();
			this.formFetching = false;
		} catch (err) {
			console.error(err);
			const opt = {
				text: 'Reports are currently unavailable, please check back later.',
				imgUrl: 'owo-cry.png',
			};
			await this.$modal(opt).showError();
			this.$router.push('/');
		}
	},
};
</script>

<style scoped>
.guild-user-text {
	display: flex;
	align-items: flex-end;
}

.avatar-icon {
	margin-bottom: 20px;
	margin-right: 10px;
}

.transition {
	transition: all 0.5s ease;
}

.error-message {
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	color: rgb(255, 82, 82);
	padding-left: 17px;
}

.filesize-message {
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	padding-right: 20px;
}

.file-messages {
	display: flex;
	flex-direction: row-reverse;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 20px;
}
</style>
