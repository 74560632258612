<template>
	<div>
		<div class="preview" v-for="(file, index) in files" :key="file.name">
			<v-img
				ref="image"
				class="preview-image"
				contain
				max-width="100px"
				:src="file.dataurl"
			>
			</v-img>
			<div class="preview-info">
				<div>{{ file.name }}</div>
			</div>
			<div class="remove-button-div">
				<v-btn
					small
					@click="removeFile(index)"
					class="remove-button"
					icon
					color="error"
				>
					<v-icon small>mdi-close</v-icon>
				</v-btn>
			</div>
		</div>
		<div :class="{ dropbox: true, 'drag-hover': dragClass }">
			<input
				type="file"
				name="photo"
				@change="fileAdded($event.target.files)"
				@drop.prevent="
					dragClass = null;
					fileAdded($event.dataTransfer.files);
				"
				@dragover.prevent="dragClass = true"
				@dragleave="dragClass = null"
				accept="image/*"
				class="input-file"
				multiple
			/>
			<p class="ma-0">
				Drag your images here <br />
				or click to browse
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FileUpload',
	data: () => ({
		files: [],
		dragClass: null,
	}),
	computed: {
		formattedFileSize() {
			const size = this.fileSize;
			let sizeString = '0B';
			const mb = 1024 * 1024;
			const kb = 1024;
			if (size > mb) {
				sizeString = Math.round((size / mb) * 10) / 10 + 'MB';
			} else if (size > kb) {
				sizeString = Math.round((size / kb) * 10) / 10 + 'KB';
			} else {
				sizeString = size + 'B';
			}
			return sizeString + ' / 5MB';
		},
	},
	fileSize() {
		this.files.reduce((prev, curr) => {
			return prev + curr.size;
		}, 0);
	},
	methods: {
		fileAdded(files) {
			files.forEach(this.addFile);
			this.$emit('files', this.files);
		},
		async addFile(file) {
			if (!file) {
				const opt = {
					text: "That's an invalid file! Try a different one!",
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
			if (!file.type || !/image\/\w+/gi.test(file.type)) {
				const opt = {
					text: "That's an invalid file! Files must be an image type",
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}
			if (file.size > 5 * 1024 * 1024) {
				const opt = {
					text: 'That file is too big! Files must be under 5MB.',
					imgUrl: 'owo-cry.png',
				};
				return this.$modal(opt).showError();
			}

			try {
				await this.getFileSrc(file);
				this.removeFileWithName(file.name);
				this.files.push(file);
			} catch (err) {
				console.error(err);
				const opt = {
					text: 'Failed to open file.',
					imgUrl: 'owo-cry.png',
				};
				this.$modal(opt).showError();
			}
		},
		getFileSrc(file) {
			return new Promise((res, rej) => {
				const reader = new FileReader();
				reader.onload = (e) => {
					file.dataurl = e.target.result;
					res();
				};
				reader.onerror = (e) => {
					rej(e);
				};
				reader.readAsDataURL(file);
			});
		},
		removeFileWithName(fileName) {
			const index = this.files.findIndex((ele) => ele.name === fileName);
			if (index >= 0) {
				this.removeFile(index);
			}
		},
		removeFile(index) {
			this.files.splice(index, 1);
			this.$emit('files', this.files);
		},
		removeFiles() {
			this.files = [];
			this.$emit('files', this.files);
		},
	},
};
</script>

<style scoped lang="scss">
.dropbox {
	outline: 2px dashed var(--v-primary-base); /* the dash box */
	background: var(--v-background-darken1);
	outline-offset: -10px;
	color: white;
	min-height: 100px; /* minimum height */
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.5s ease;
}

.input-file {
	opacity: 0;
	width: 100%;
	height: 200px;
	position: absolute;
	cursor: pointer;
}

.dropbox:hover {
	outline-offset: -5px;
	background: var(--v-background-base);
}

.dropbox p {
	font-size: 1.2em;
	text-align: center;
	padding: 50px 0;
}

.preview {
	background: var(--v-background_dark-base);
	padding: 10px 10px;
	margin: 10px 10px;
	border-radius: 8px;
	display: flex;
	align-items: center;
}

.preview-image {
	position: relative;
	margin-right: 10px;
}

.remove-button-div {
	flex-grow: 1;
	display: flex;
	justify-content: flex-end;
}

.preview-info {
	display: flex;
	justify-content: space-between;
	padding-top: 5px;
}

.drag-hover {
	outline-offset: -5px !important;
	background: var(--v-background-base) !important;
}
</style>
